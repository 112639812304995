import {
  logEvent,
  page,
  track,
  EVENT_TYPE_INTERACTION,
  EVENT_PARAM_TYPE_STRING,
} from '@artemis/utils/analytics';
import {
  EVENT_ACTION_PROMOTIONAL_LANDING_PAGE_IMPRESSION,
  EVENT_ACTION_PROMOTIONAL_LANDING_PAGE_MAIN_CTA_CLICK,
} from '@artemis/utils/analyticsEvents';

const EVENT_ACTION_INVITE_LANDING_CONTINUE_IN_APP_CLICK =
  'RW_INVITE_LANDING_CONTINUE_IN_APP_CLICK';

export const logInviteLandingPageImpression = (promo, type) => {
  page({
    PROMO_CODE: promo,
    PROMO_TYPE: type,
  });
  logEvent(
    EVENT_ACTION_PROMOTIONAL_LANDING_PAGE_IMPRESSION,
    [
      { key: 'PROMO_CODE', value: promo, type: EVENT_PARAM_TYPE_STRING },
      { key: 'PROMO_TYPE', value: type, type: EVENT_PARAM_TYPE_STRING },
    ],
    EVENT_TYPE_INTERACTION,
  );
};

export const logInviteLandingContinueInAppClick = (promo, type) => {
  track(EVENT_ACTION_INVITE_LANDING_CONTINUE_IN_APP_CLICK, {
    PROMO_CODE: promo,
    PROMO_TYPE: type,
  });
  logEvent(
    EVENT_ACTION_PROMOTIONAL_LANDING_PAGE_MAIN_CTA_CLICK,
    [
      { key: 'PROMO_CODE', value: promo, type: EVENT_PARAM_TYPE_STRING },
      { key: 'PROMO_TYPE', value: type, type: EVENT_PARAM_TYPE_STRING },
    ],
    EVENT_TYPE_INTERACTION,
  );
};
