
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/order/get-started/[promo]",
      function () {
        return require("private-next-pages/order/get-started/[promo].jsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/order/get-started/[promo]"])
      });
    }
  