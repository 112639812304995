export const PROMO_CODE_TYPES = {
  SHAREABLE_GIFT: 'SHAREABLE_GIFT',
  REFERRAL: 'REFERRAL', // Friend/individual invite
  CORPORATE: 'CORPORATE',
  CAMPAIGN: 'CAMPAIGN', // Big Bang merchant invite
  REFERRAL_TEAM: 'REFERRAL_TEAM',
  CART_CHANNEL: 'CART_CHANNEL',
  TEAMS_COMPANY: 'TEAMS_COMPANY',
  UNIFIED: 'UNIFIED',
  DROP_CHANNEL: 'DROP_CHANNEL',
  MERCHANT_REFERRAL: 'MERCHANT_REFERRAL',
  VERIFIED_EMAIL_CAMPAIGN: 'VERIFIED_EMAIL_CAMPAIGN',
  WORKSPACE_MEMBER_INVITE: 'WORKSPACE_MEMBER_INVITE',
  ORDER_POOL: 'ORDER_POOL',
  EMPLOYEE_PRICING: 'EMPLOYEE_PRICING',
};

export const PERSONAL_PROMO_CODE_TYPES = [
  PROMO_CODE_TYPES.REFERRAL,
  PROMO_CODE_TYPES.REFERRAL_TEAM,
];
