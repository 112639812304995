import React, { useEffect } from 'react';
import Error from 'next/error';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Metadata from '@artemis/components/Metadata';
import NavBar from '@artemis/components/NavBar';
import NavLogos from '@artemis/components/NavBar/NavLogos';
import PageContainer from '@artemis/components/PageContainer';
import ResponsiveImage from '@artemis/components/ResponsiveImage';
import {
  FormattedMessage,
  useFormatMessage,
} from '@artemis/integrations/contentful/utils';
import {
  getPromoCodeError,
  getPromoCodeSubjectName,
  getPromoCodeType,
} from '@artemis/store/promoCodes/selectors';
import { PROMO_PARAM } from '@artemis/utils/query/constants';
import useTrackWebVitals, { PAGE_NAME } from '@artemis/utils/useTrackWebVitals';
import { logInviteLandingPageImpression } from './analytics';
import { PROMO_CODE_TYPES } from './constants';
import { InviteCard } from './InviteCard';

const NavBarLink = styled.a`
  margin: auto;
`;

const PromoSection = styled.div`
  background-color: ${props => props.theme.rtColors.black100};
  padding: 32px 24px;
  > * {
    margin-left: auto;
    margin-right: auto;
  }

  ${props => props.theme.isTablet`
    padding: 53px 24px;
  `}
`;

const Badges = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  > * {
    margin-right: 12px;
    :last-child {
      margin-left: 0;
    }
  }
  ${props => props.theme.isTablet`
    margin-top: 40px;
    > * {
      margin-right: 20px;
      :last-child {
        margin-left: 0;
      }
    }
  `}
`;

const AppStore = styled(ResponsiveImage).attrs({
  id: 'landing.appStore.outline.img',
})`
  display: inline-block;
  height: 28px;
  width: 84px;
  ${props => props.theme.isTablet`
    height: 43px;
    width: 129px;
  `}
`;

const GooglePlay = styled(ResponsiveImage).attrs({
  id: 'landing.googlePlay.outline.img',
})`
  display: inline-block;
  height: 28px;
  width: 94px;
  ${props => props.theme.isTablet`
    height: 43px;
    width: 145px;
  `}
`;

const Disclaimer = styled.p`
  font-family: ${props => props.theme.typography.poppins};
  font-size: 0.75rem;
  margin-top: 30px;
  text-align: center;
  ${props => props.theme.isTablet`
    font-size: 1rem;
    margin-top: 40px;
  `}
`;

const BriefSection = styled.div`
  padding: 40px 24px;
  > * {
    display: inline-block;
  }

  ${props => props.theme.isTablet`
    padding: 150px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    > * {
      display: block;
    }
  `}
`;

const Details = styled.div`
  max-width: 520px;
`;

const DetailsTitle = styled.h3`
  font-family: ${props => props.theme.typography.poppins};
  font-size: 2rem;
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  margin-bottom: 24px;

  ${props => props.theme.isTablet`
    font-size: 2.5rem;
    margin-bottom: 36px;
  `}
`;

const DetailsStep = styled.div`
  display: flex;
  padding: 12px 0;

  > * {
    :first-child {
      width: 38px;
      height: 38px;
    }
    :nth-child(2) {
      line-height: 38px;
      margin-left: 16px;
      font-size: 1.125rem;
      font-family: ${props => props.theme.typography.poppins};
      flex: 1;

      ${props => props.theme.isTablet`
        font-size: 1.5rem;
      `}
    }
  }

  ${props => props.theme.isTablet`
    font-size: 1.5rem;
    padding: 16px;
  `}
`;

const Hero = styled.div`
  margin-top: 36px;

  ${props => props.theme.isTablet`
    margin-top: 0;
    height: 575px;
  `}
`;

const InviteLandingPage = () => {
  const { query } = useRouter();
  const subjectName = useSelector(getPromoCodeSubjectName);
  const err = useSelector(getPromoCodeError);
  const promoCodeType = useSelector(getPromoCodeType);

  const pageTitle = useFormatMessage({
    entry:
      promoCodeType === PROMO_CODE_TYPES.CAMPAIGN && subjectName
        ? 'invite.pageTitleMerchant'
        : 'invite.pageTitleGeneric',
    values: { merchantName: subjectName },
  });
  const logoUrl = useFormatMessage({
    entry: 'landing.logoLink',
  });
  const steps = useFormatMessage({
    entry: 'invite.details.steps',
  }).split('\n');

  useTrackWebVitals(PAGE_NAME.INVITE_LANDING);

  useEffect(() => {
    if (Object.values(PROMO_CODE_TYPES).includes(promoCodeType)) {
      logInviteLandingPageImpression(query[PROMO_PARAM], promoCodeType);
    }
  }, [promoCodeType]);

  if (err) return <Error statusCode={404} />;

  return (
    <>
      <Metadata title={pageTitle} noindex nofollow />
      <NavBar>
        <NavBarLink href={logoUrl}>
          <NavLogos logos={['promo.ritualLogo.img']} />
        </NavBarLink>
      </NavBar>
      <PageContainer>
        <PromoSection>
          <InviteCard />
          <Badges>
            <AppStore />
            <GooglePlay />
          </Badges>
          {promoCodeType === PROMO_CODE_TYPES.REFERRAL && (
            <Disclaimer>
              <FormattedMessage entry="invite.newUsersOnlyDisclaimer" />
            </Disclaimer>
          )}
        </PromoSection>
        <BriefSection>
          <Details>
            <DetailsTitle>
              <FormattedMessage entry="invite.details.title" />
            </DetailsTitle>
            {steps.map((step, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <DetailsStep key={index}>
                <ResponsiveImage id="icons.tealCheck.img" />
                <span>{step}</span>
              </DetailsStep>
            ))}
          </Details>
          <Hero>
            <ResponsiveImage id="invite.hero.img" />
          </Hero>
        </BriefSection>
      </PageContainer>
    </>
  );
};

export default InviteLandingPage;
