import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ResponsiveImage from '@artemis/components/ResponsiveImage';
import { PERSONAL_PROMO_CODE_TYPES, PROMO_CODE_TYPES } from './constants';

const Image = styled.img`
  height: 96px;
  object-fit: cover;
  ${({ $objectFitContain }) => $objectFitContain && 'object-fit: contain'};
  width: ${({ $square }) => ($square ? '96px' : '176px')};
  ${props => props.theme.isTablet`
    height: 136px;
    width: ${({ $square }) => ($square ? '136px' : '250px')};
  `}
`;

const ImageContainer = styled.div`
  margin: auto;
  picture {
    height: 96px;
    width: 96px;
    object-fit: cover;
    display: block;
  }
  img {
    border-radius: 11px;
  }

  ${props => props.theme.isTablet`
  picture {
    height: 136px;
    width: 136px;
  }
  img {
    border-radius: 15px;
  }
  `}
`;

export const SubjectImage = ({ src, alt, type }) => {
  const isPromoPersonal = PERSONAL_PROMO_CODE_TYPES.includes(type);
  const defaultImage = isPromoPersonal
    ? 'invite.profileImageDefault.img'
    : 'invite.ritualIcon.img';

  return (
    <ImageContainer>
      {src ? (
        <Image
          src={src}
          alt={alt}
          data-testid="subject-image"
          $square={isPromoPersonal}
          $objectFitContain={type === PROMO_CODE_TYPES.CORPORATE}
        />
      ) : (
        <ResponsiveImage id={defaultImage} />
      )}
    </ImageContainer>
  );
};

SubjectImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string.isRequired,
  type: PropTypes.string,
};
