import React from 'react';
import InviteLandingPage from '@artemis/containers/InviteLandingPage';
import { loadPromoCode } from '@artemis/store/promoCodes/slice';
import { loadResourceSet } from '@artemis/store/resourceSet/slice';
import commonStrings from '@artemis/utils/common-strings.json';
import { PROMO_PARAM } from '@artemis/utils/query/constants';

const Invite = props => <InviteLandingPage {...props} />;

// This gets called on every request
Invite.getInitialProps = async ctx => {
  const { contentfulLocale, isServer, query, store } = ctx;
  // Fetch data from external API
  if (isServer) {
    store.dispatch(
      loadResourceSet({
        name: ['landing', 'promo', 'invite', ...commonStrings],
        locale: contentfulLocale,
      }),
    );
    store.dispatch(loadPromoCode(query[PROMO_PARAM]));
  }
  return {};
};

export default Invite;
