import React from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { QRCodeSVG } from 'qrcode.react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useFormatMessage } from '@artemis/integrations/contentful/utils';
import {
  getPromoCodeLocalizedBody,
  getPromoCodeLocalizedMessage,
  getPromoCodeLocalizedOffers,
  getPromoCodeSubjectImageUrl,
  getPromoCodeSubjectName,
  getPromoCodeTeamName,
  getPromoCodeTotalValue,
  getPromoCodeTotalValueMicro,
  getPromoCodeType,
} from '@artemis/store/promoCodes/selectors';
import { PROMO_PARAM } from '@artemis/utils/query/constants';
import useBranchLink from '@artemis/utils/useBranchLink';
import { logInviteLandingContinueInAppClick } from './analytics';
import { PROMO_CODE_TYPES } from './constants';
import { SubjectImage } from './SubjectImage';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden; // Enforce children to follow border-radius
  border-radius: 20px;
  box-shadow: 0px 6px 11px rgba(0, 0, 0, 0.07),
    0px 0.751293px 4.09272px rgba(0, 0, 0, 0.035);
  background-color: ${props => props.theme.rtColors.background100};
  max-width: 550px;
`;

const ImageContainer = styled.div`
  margin: 32px auto 16px;
  ${props => props.theme.isTablet`
    margin: 40px auto 24px;
  `}
`;

const Details = styled.div`
  color: ${props => props.theme.rtColors.black900};
  margin: 0 16px 32px;
  ${props => props.theme.isTablet`
    margin: 0 24px 40px;
  `}
`;

const Reward = styled.h2`
  text-align: center;
  font-family: ${props => props.theme.typography.poppins};
  font-size: 1.75rem;
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  color: ${props => props.theme.rtColors.black1000};
  margin-bottom: 16px;

  ${props => props.theme.isTablet`
    font-size: 2.5rem;
    margin-bottom: 24px;
  `}
`;

const QRCodeContainer = styled.div`
  display: none;

  ${props => props.theme.isTablet`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `}
`;

const Instructions = styled.ol`
  font-family: ${props => props.theme.typography.poppins};
  font-size: 1.125rem;
  list-style-position: inside;
  margin: 0 0 16px;
  padding: 0;
  text-align: center;
  ${props => props.theme.isTablet`
    margin: 0 0 24px;
  `}
`;

const MobileCTA = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > button {
    background-color: ${props => props.theme.rtColors.teal500};
    > div {
      font-family: ${props => props.theme.typography.poppins};
    }
  }

  ${props => props.theme.isTablet`
    display: none;
  `}
`;

const Description = styled.p`
  font-family: ${props => props.theme.typography.poppins};
  font-size: 1rem;
  margin-bottom: 16px;
  text-align: center;
  ${props => props.theme.isTablet`
    font-size: 1.125rem;
    margin-bottom: 24px;
  `}
`;

const BranchLink = styled.a`
  background-color: ${props => props.theme.rtColors.teal500};
  color: ${props => props.theme.rtColors.white};
  font-family: ${props => props.theme.typography.poppins};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  padding: 10px 30px;
  border-radius: 1000pt;
  text-decoration: none;
`;

const getTitleKey = (
  promoCodeType,
  totalValueMicro,
  teamName,
  subjectName,
  localizedMessage,
) => {
  switch (promoCodeType) {
    case PROMO_CODE_TYPES.CAMPAIGN:
      if (subjectName) {
        return totalValueMicro
          ? 'invite.rewardMerchant'
          : 'invite.rewardMerchantNoValue';
      }
      return totalValueMicro ? 'invite.rewardGeneric' : 'invite.orderToday';
    case PROMO_CODE_TYPES.REFERRAL:
      return 'invite.rewardReferral';
    case PROMO_CODE_TYPES.REFERRAL_TEAM:
      return teamName
        ? 'invite.rewardReferralTeam'
        : 'invite.rewardReferralTeamNoName';
    case PROMO_CODE_TYPES.UNIFIED:
      if (totalValueMicro === 0 && localizedMessage) {
        // This is small hack since localizedMessage does not point to a
        // Contentful entry. When useFormatMessage receives localizedMessage as
        // the entry, it return the value of localizedMessage.
        return localizedMessage;
      }
      return totalValueMicro ? 'invite.rewardGeneric' : 'invite.orderToday';
    default:
      if (totalValueMicro && subjectName) {
        return 'invite.rewardCorporate';
      }
      return totalValueMicro ? 'invite.rewardGeneric' : 'invite.orderToday';
  }
};

export const InviteCard = ({ className }) => {
  const { query } = useRouter();
  const promo = query[PROMO_PARAM];

  const subjectName = useSelector(getPromoCodeSubjectName);
  const subjectImageUrl = useSelector(getPromoCodeSubjectImageUrl);
  const teamName = useSelector(getPromoCodeTeamName);
  const totalValue = useSelector(getPromoCodeTotalValue);
  const totalValueMicro = useSelector(getPromoCodeTotalValueMicro);
  const localizedOffers = useSelector(getPromoCodeLocalizedOffers);
  const localizedMessage = useSelector(getPromoCodeLocalizedMessage);
  const localizedBody = useSelector(getPromoCodeLocalizedBody);
  const promoCodeType = useSelector(getPromoCodeType);

  const rewardText = useFormatMessage({
    entry: getTitleKey(
      promoCodeType,
      totalValueMicro,
      teamName,
      subjectName,
      localizedMessage,
    ),
    values: { subjectName, totalValue, teamName },
  });
  const initialQRStep = useFormatMessage({
    entry: 'invite.qrInstructions',
  });
  const initialCTAStep = useFormatMessage({
    entry: 'invite.ctaInstructions',
  });
  const defaultLine = useFormatMessage({
    entry: 'invite.rewardLineDefault',
  });
  const descriptionCTA = useFormatMessage({
    entry:
      promoCodeType === PROMO_CODE_TYPES.REFERRAL_TEAM
        ? 'invite.ctaTeamDescription'
        : 'invite.ctaGenericDescription',
  });
  const ctaText = useFormatMessage({
    entry: 'invite.cta',
  });
  const subjectImageAlt = useFormatMessage({
    entry: 'invite.subjectImageLabel',
  });
  const descriptionQR = useFormatMessage({
    entry:
      promoCodeType === PROMO_CODE_TYPES.REFERRAL_TEAM
        ? 'invite.qrTeamDescription'
        : 'invite.qrGenericDescription',
  });

  const branchUrl = useBranchLink({ promoCode: promo });

  const getBodyText = (initialStep, description) => {
    if (
      promoCodeType !== PROMO_CODE_TYPES.REFERRAL_TEAM &&
      totalValueMicro > 0
    ) {
      return (
        <Instructions>
          <li>{initialStep}</li>
          {localizedOffers.length > 0 ? (
            localizedOffers.map(line => <li key={line}>{line}</li>)
          ) : (
            <li>{defaultLine}</li>
          )}
        </Instructions>
      );
    }
    if (localizedBody && totalValueMicro === 0) {
      return <Description>{localizedBody}</Description>;
    }
    return <Description>{description}</Description>;
  };

  return (
    <Container className={className}>
      <ImageContainer>
        <SubjectImage
          src={subjectImageUrl}
          alt={subjectImageAlt}
          type={promoCodeType}
        />
      </ImageContainer>
      <Details>
        <Reward>{rewardText}</Reward>
        <QRCodeContainer>
          {getBodyText(initialQRStep, descriptionQR)}
          <QRCodeSVG value={branchUrl} size="106" />
        </QRCodeContainer>
        <MobileCTA>
          {getBodyText(initialCTAStep, descriptionCTA)}
          <BranchLink
            href={branchUrl}
            onClick={() =>
              logInviteLandingContinueInAppClick(promo, promoCodeType)
            }
          >
            {ctaText}
          </BranchLink>
        </MobileCTA>
      </Details>
    </Container>
  );
};

InviteCard.propTypes = {
  className: PropTypes.string,
};
